import { router } from 'ui-utilities';

export default function (el, {
    actions,
    events,
}) {
    const downloadButtons = el.querySelectorAll('nav > a');
	const downloadForm = el.querySelector('form');

	const handleCounterIncrement = () => {
		const formData = new FormData(downloadForm);

		function cb(res) {
            console.log(res);
        }

		events.emit(actions.getCsrfToken, {
            cb: () => {
                router.post({ formData, cb });
            },
        });
	}

	downloadButtons && downloadButtons.forEach((btn) => {
		btn.addEventListener('click', () => {
			console.log('hello???');
			handleCounterIncrement();
		});
	});
}
